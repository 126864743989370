import './styles.scss';

import { usePaymentFormula, useRemainingMonths } from '@components/subscription-plan/hooks';
import { useOne } from '@refinedev/core';
import { POS_PLAN_ID } from '@zodinet-pos/core-ui';
import { Button, Col, Form, Input, Row, Tag } from 'antd';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { APPLICATIONS } from '../../constants/index.constant';
import { CurrencyType } from '../../enums/currency-type.enum';
import { NApplication } from '../../interfaces/application';
import { NRegistration } from '../../interfaces/registration';
import { formatPriceBaseOnCurrency } from '../../utils/resource';
import { CustomModal } from '../modules/modal-custom';
import { IForm } from '.';

type IDrawerRegistrationForm = {
    isOpen: boolean;
    isLoading: boolean;
    registration: NRegistration.IRegistrationForDashboard;
    onClose: () => void;
    handlePayment: (values: IForm, totalPayment: number) => void;
};

export const UpgradeLicenseModal: React.FC<IDrawerRegistrationForm> = ({
    isOpen,
    isLoading,
    registration,
    onClose,
    handlePayment,
}) => {
    const { t } = useTranslation(['common']);
    const [form] = Form.useForm();

    const [newPlan, setNewPlan] = useState<NApplication.IExtraAppPlan>();

    const { data: extraAppPlans } = useOne<NApplication.IExtraAppPlanData>({
        resource: 'v1/application/extra-app-plans',
        id: APPLICATIONS.POS.id,
        queryOptions: {
            enabled: true,
        },
    });
    const extraPlanPrice = registration.planPrice;

    const [isRenderTotal, setIsRenderTotal] = useState<boolean>(false);
    const expiredTime = useRemainingMonths(registration);

    const renderTotalPayment = useMemo(() => {
        const _price = newPlan ? (newPlan?.pricing || 0) - (registration?.planPrice || 0) : 0;
        setIsRenderTotal(false);
        return usePaymentFormula({
            price: _price,
            expiredTime,
            qtyTable: registration?.qtyTable,
            fnbPrice: newPlan?.fnbPricing || 0,
        });
    }, [isRenderTotal]);

    return (
        <CustomModal
            open={isOpen}
            footer={false}
            closable={!isLoading}
            onCancel={onClose}
            maskClosable={false}
            width={700}
            className="upgrade-license"
        >
            <div className="flex flex-row justify-between mb-6">
                <div>
                    <p className="text-2xl font-bold">{t(`plan_form.fields.service_package`)} </p>
                    <Tag bordered={false} className="p-2 mt-2 mb-2">
                        <span className="font-bold">
                            {t(
                                `plan_form.plan_type.${registration.extraAppPlanName.toLowerCase()}`,
                            ).toUpperCase()}{' '}
                        </span>
                    </Tag>
                </div>
            </div>
            <Form
                layout="vertical"
                className="upgrade-modal"
                form={form}
                onFinish={(value) => handlePayment(value, renderTotalPayment)}
            >
                <Form.Item
                    className="mt-2"
                    name={'extraAppPlanId'}
                    rules={[
                        {
                            required: true,
                            message: t('plan_form.fields.service_package_required'),
                        },
                    ]}
                >
                    <Row className="w-full mb-2" gutter={[12, 12]}>
                        {extraAppPlans?.data?.plans
                            ?.filter((plan) => plan.id !== POS_PLAN_ID.STANDARD_FNB)
                            .map((extraAppPlan) => (
                                <Col key={extraAppPlan.id} xs={24} md={8}>
                                    <button
                                        type="button"
                                        className={`text-center w-full rounded-lg ${
                                            newPlan?.id === extraAppPlan.id
                                                ? `selected-service`
                                                : `plan-item`
                                        }`}
                                        onClick={() => {
                                            setIsRenderTotal(true);
                                            setNewPlan(extraAppPlan);
                                            form.setFieldsValue({
                                                extraAppPlanId: extraAppPlan.id,
                                            });
                                        }}
                                        disabled={extraAppPlan.pricing <= extraPlanPrice}
                                    >
                                        <div className={'icon-plan'}>
                                            <img
                                                src="/images/icons/plan.svg"
                                                alt="dots-vertical"
                                                className={'action-icon'}
                                            />
                                        </div>
                                        <p
                                            className={`name-plan ${
                                                extraAppPlan.pricing <= extraPlanPrice
                                                    ? 'disable'
                                                    : ''
                                            }`}
                                        >
                                            {t(
                                                `plan_form.plan_type.${extraAppPlan.title.toLowerCase()}`,
                                            ).toUpperCase()}
                                        </p>
                                        <p
                                            className={`price-plan ${
                                                extraAppPlan.pricing <= extraPlanPrice
                                                    ? 'disable'
                                                    : ''
                                            }`}
                                        >
                                            {formatPriceBaseOnCurrency(
                                                ((extraAppPlan.pricing || 0) * 100) / 100,
                                                CurrencyType.Vnd,
                                            )}
                                            {extraAppPlan.pricing
                                                ? `/${t('plan_form.pc_month')}`
                                                : ''}
                                        </p>
                                    </button>
                                </Col>
                            ))}
                    </Row>
                </Form.Item>

                <Form.Item
                    className="mt-2"
                    label={t('plan_form.fields.reference_code')}
                    name={'referenceCode'}
                    initialValue={registration.referenceCode}
                >
                    <Input placeholder={t('plan_form.fields.reference_code_placeholder')} />
                </Form.Item>
                <p className="my-2">
                    *{t('plan_form.fields.total_price')}:{' '}
                    {formatPriceBaseOnCurrency(renderTotalPayment, CurrencyType.Vnd)}
                </p>
                <Button
                    type="primary"
                    className="w-full flex justify-center"
                    htmlType="submit"
                    loading={isLoading}
                >
                    {t('plan_form.actions.payment_btn')}
                </Button>
            </Form>
        </CustomModal>
    );
};
