import { CloseOutlined, PayCircleOutlined } from '@ant-design/icons';
import { mapAntdSorterToCrudSorting } from '@refinedev/antd';
import { useApiUrl, useCustomMutation, useTable } from '@refinedev/core';
import { yearToMonths } from '@utils/function';
import type { MenuProps } from 'antd';
import { Dropdown, notification, Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PAGE_SIZE, VN_DATE_FORMAT_MINUTE } from '../../constants/index.constant';
import { SubscriptionOrderStatusEnum } from '../../enums/subscription-order-status.enum';
import { SubscriptionOrderTypeEnum } from '../../enums/subscription-order-type.enum';
import { NSubscriptionOrder } from '../../interfaces/subscription-order';
import { formatDate } from '../../utils/resource';
import { CustomPagination } from '../modules/pagination';

const resource = 'subscription-orders';

export const SubscriptionOrder: React.FC<{
    isRefetch: boolean;
    handlePaymentSubscriptionOrder: (subscriptionOrder: NSubscriptionOrder.IInfo) => void;
    setIsRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isRefetch, handlePaymentSubscriptionOrder, setIsRefetch }) => {
    const apiUrl = useApiUrl();

    const { mutate } = useCustomMutation();
    const { t } = useTranslation(['common']);

    useEffect(() => {
        if (isRefetch) {
            tableQueryResult.refetch();
            setIsRefetch(false);
        }
    }, [isRefetch]);

    const { tableQueryResult, current, setCurrent, pageSize, setPageSize, setSorters } =
        useTable<NSubscriptionOrder.IInfo>({
            resource: `v1/${resource}`,
            sorters: {
                initial: [
                    {
                        field: 'created_at',
                        order: 'desc',
                    },
                ],
            },
            filters: {},
            pagination: {
                mode: 'server',
                pageSize: PAGE_SIZE,
            },
        });

    const onChangeTable = (
        _: TablePaginationConfig,
        __: Record<string, FilterValue | null>,
        sorter: SorterResult<NSubscriptionOrder.IInfo> | SorterResult<NSubscriptionOrder.IInfo>[],
    ) => {
        if (sorter && Object.keys(sorter).length > 0) {
            // Map Antd:Sorter -> refine:CrudSorting
            const crudSorting = mapAntdSorterToCrudSorting(sorter);
            setSorters(crudSorting);
        }
    };

    const renderItems = (id: string, record: NSubscriptionOrder.IInfo) => {
        const items: MenuProps['items'] = [];

        switch (record.status) {
            case SubscriptionOrderStatusEnum.WAITING_PAYMENT: {
                if (!record.referenceCode) {
                    items.push({
                        key: '1',
                        label: t('subscription_order.actions.payment', { ns: 'common' }),
                        icon: <PayCircleOutlined />,
                        onClick: () => {
                            handlePaymentSubscriptionOrder(record);
                        },
                    });
                }
                items.push({
                    key: '2',
                    label: t('subscription_order.actions.canceled'),
                    icon: <CloseOutlined />,
                    onClick: () => {
                        handleCancel(id);
                    },
                });
                break;
            }

            default:
                break;
        }

        return items;
    };

    const handleCancel = (id: string) => {
        const url = `${apiUrl}/v1/${resource}/cancel/${id}`;
        mutate(
            {
                url: url,
                method: 'put',
                values: {},
                errorNotification: false,
            },
            {
                onError: (error, _, __) => {
                    // An error happened!
                    const code = error?.response?.data?.error || 'unknown';
                    notification.error({
                        className: 'error-notification',
                        message: '',
                        description: t(`plan_form.errors.${code}`, { ns: 'common' }),
                    });
                    console.error(error);
                },
                onSuccess: () => {
                    notification.success({
                        className: 'success-notification',
                        message: '',
                        description: t('plan_form.success.cancel_success', {
                            ns: 'common',
                        }),
                    });
                    tableQueryResult.refetch();
                },
            },
        );
    };

    const getQuantityContent = (record: NSubscriptionOrder.IInfo): string => {
        let text = '--';
        if (!record.quantity) return text;

        switch (record.type) {
            case SubscriptionOrderTypeEnum.UPGRADE_TAX_TRANSACTION:
            case SubscriptionOrderTypeEnum.UPGRADE_PAYGATE_TRANSACTION:
            case SubscriptionOrderTypeEnum.UPGRADE_TICKET_TRANSACTION: {
                text = t('plan_form.turn', { quantity: record.quantity });
                break;
            }
            case SubscriptionOrderTypeEnum.SUBSCRIBE_CLOUD:
            case SubscriptionOrderTypeEnum.SUBSCRIBE_FNET_MOBILE:
            case SubscriptionOrderTypeEnum.SUBSCRIBE_COMBO_VIP: {
                text = `${record.quantity} ${t('subscription_order.frequency_type.month')}`;
                break;
            }
            case SubscriptionOrderTypeEnum.RENEW_LICENSE:
            case SubscriptionOrderTypeEnum.ADD_ON_PLAN: {
                text = `${yearToMonths(record.quantity)} ${t(
                    'subscription_order.frequency_type.month',
                )}`;
                break;
            }
            case SubscriptionOrderTypeEnum.UPGRADE_TABLE:
                text = `${record.quantity} PC`;
                break;

            default:
                text = `${text} PC`;
                break;
        }
        return text;
    };

    return (
        <section className="partner-list-container">
            <div className="overflow-hidden">
                <div className="list-content table-wrapper">
                    <Table
                        dataSource={tableQueryResult?.data?.data}
                        pagination={false}
                        rowKey="id"
                        onChange={onChangeTable}
                        scroll={{ y: '100%', x: '800px' }}
                    >
                        <Table.Column
                            title={<>{t('subscription_order.type')}</>}
                            dataIndex="type"
                            key="type"
                            render={(text, _) => (
                                <p className="table-tbody-text">
                                    {t(`subscription_order.types.${text}`)}
                                </p>
                            )}
                            width={150}
                        />
                        <Table.Column
                            title={<>{t('subscription_order.quantity')}</>}
                            dataIndex="quantity"
                            key="quantity"
                            render={(_, record: NSubscriptionOrder.IInfo) => (
                                <p className="table-tbody-text">{getQuantityContent(record)}</p>
                            )}
                            width={150}
                        />
                        <Table.Column
                            title={<>{t('subscription_order.created_at')}</>}
                            dataIndex="createdAt"
                            key="createdAt"
                            render={(text, _) => (
                                <p className="table-tbody-text">
                                    {formatDate(text, VN_DATE_FORMAT_MINUTE)}
                                </p>
                            )}
                            width={150}
                        />
                        <Table.Column
                            title={<>{t('subscription_order.status')}</>}
                            dataIndex="status"
                            key="status"
                            render={(text: SubscriptionOrderStatusEnum, _) => (
                                <p className="table-tbody-text">
                                    {t(`subscription_order.status_type.${text}`)}
                                </p>
                            )}
                            width={150}
                        />

                        <Table.Column
                            dataIndex="id"
                            key="action"
                            render={(id, record: NSubscriptionOrder.IInfo) => {
                                const items = renderItems(id, record);
                                if (!items.length) return <></>;
                                return (
                                    <div className="flex justify-end items-center">
                                        <Dropdown
                                            menu={{
                                                items,
                                            }}
                                            placement="bottomRight"
                                            arrow
                                            overlayClassName="contact-dropdown-container"
                                            trigger={['click']}
                                        >
                                            <img
                                                src="/images/icons/dots-vertical.svg"
                                                alt="more"
                                                className="cursor-pointer"
                                            />
                                        </Dropdown>
                                    </div>
                                );
                            }}
                            width={100}
                        />
                    </Table>
                </div>
            </div>

            <div className="pagination-container pt-3 pb-4 px-6">
                <CustomPagination
                    pageSize={pageSize}
                    total={tableQueryResult?.data?.total}
                    current={current}
                    onChange={(value: number, size: number) => {
                        setCurrent(value);
                        setPageSize(size);
                    }}
                />
            </div>
        </section>
    );
};
