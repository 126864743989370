import { useRemainingMonths } from '@components/subscription-plan/hooks';
import { CurrencyType } from '@enums/currency-type.enum';
import { SubscriptionOrderTypeEnum } from '@enums/subscription-order-type.enum';
import { NRegistration } from '@interfaces/registration';
import { getLocalTime } from '@utils/date';
import { formatPriceBaseOnCurrency } from '@utils/resource';
import { POS_PLAN_ID } from '@zodinet-pos/core-ui';
import { Button, Tag } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    registration: NRegistration.IRegistrationForDashboard;
    onOpenUpgradeModal: (type: SubscriptionOrderTypeEnum) => void;
};

export const PlanInformation: FC<Props> = ({ registration, onOpenUpgradeModal }) => {
    const { t, i18n } = useTranslation('common');

    const remainingMonths: number = useRemainingMonths(registration);
    const isFreePlan = registration?.extraAppPlanId === POS_PLAN_ID.FREE;

    const subscriptionContents = (
        registration: NRegistration.IRegistrationForDashboard,
    ): { label: string; value: string | number }[] => [
        {
            label: t('plan_form.member_since'),
            value: dayjs(getLocalTime(registration.startDate))
                .locale(`${i18n.language}`)
                .format('ll'),
        },
        {
            label: t('plan_form.next_payment'),
            value: dayjs(getLocalTime(registration.expiredDate))
                .locale(`${i18n.language}`)
                .format('ll'),
        },
        {
            label: t('plan_form.remaining_time', {
                frequency: t(
                    `subscription_order.frequency_type.${
                        registration.isTrial ? registration.frequency : 'month'
                    }`,
                ),
            }),
            value: remainingMonths,
        },
    ];

    return (
        <div className="plan-information">
            <div className="flex flex-col sm:flex-row justify-between items-center">
                <div className="w-full">
                    <h2 className="text-xl mb-2">{t(`plan_form.fields.service_package`)}</h2>
                    <Tag bordered={false} className="px-3 py-1 text-sm">
                        <span>
                            {t(
                                `plan_form.plan_type.${registration.extraAppPlanName.toLowerCase()}`,
                            ).toUpperCase()}
                        </span>
                        {registration.isTrial && (
                            <span className="text-slate-400"> ({t('plan_form.trial')})</span>
                        )}
                    </Tag>
                </div>
                <div className="text-right mt-4 sm:mt-0">
                    <p className="text-2xl font-bold mb-1">
                        {formatPriceBaseOnCurrency(registration.planPrice, CurrencyType.Vnd)}
                    </p>
                    <p className="text-slate-400 text-base">/{t('plan_form.pc_month')}</p>
                </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-3 gap-8 my-8 text-sm font-normal">
                {subscriptionContents(registration).map((i, index) => (
                    <div key={index} className="text-center">
                        <p className="text-slate-400 mb-2">{i.label}</p>
                        <p className="font-semibold text-base">{i.value}</p>
                    </div>
                ))}
            </div>

            <div className="flex flex-col gap-4">
                <div className="flex flex-col sm:flex-row gap-4">
                    {!isFreePlan && (
                        <Button
                            type="default"
                            onClick={() => {
                                onOpenUpgradeModal(SubscriptionOrderTypeEnum.RENEW_LICENSE);
                            }}
                            className="button-action"
                        >
                            {t('plan_form.actions.renew_license')}
                        </Button>
                    )}
                    {!registration.isTrial &&
                        registration.extraAppPlanId !== POS_PLAN_ID.PREMIUM && (
                            <Button
                                type="primary"
                                onClick={() => {
                                    onOpenUpgradeModal(SubscriptionOrderTypeEnum.UPGRADE_LICENSE);
                                }}
                                className="button-action"
                            >
                                {t('plan_form.actions.upgrade_license')}
                            </Button>
                        )}
                </div>
                {!registration.isTrial &&
                    [POS_PLAN_ID.STANDARD].includes(registration.extraAppPlanId) && (
                        <Button
                            type="primary"
                            onClick={() => {
                                onOpenUpgradeModal(SubscriptionOrderTypeEnum.ADD_ON_PLAN);
                            }}
                            className="button-action"
                        >
                            {t('plan_form.actions.add_on_plan')}
                        </Button>
                    )}
            </div>
        </div>
    );
};
