export enum SubscriptionOrderTypeEnum {
    UPGRADE_TABLE = 'upgrade_table',
    UPGRADE_LICENSE = 'upgrade_license',
    RENEW_LICENSE = 'renew_license',
    ADD_ON_PLAN = 'add_on_plan',
    UPGRADE_TAX_TRANSACTION = 'upgrade_tax_transaction',
    UPGRADE_PAYGATE_TRANSACTION = 'upgrade_paygate_transaction',
    UPGRADE_TICKET_TRANSACTION = 'upgrade_ticket_transaction',
    SUBSCRIBE_CLOUD = 'subscribe_cloud',
    SUBSCRIBE_FNET_MOBILE = 'subscribe_fnet_mobile',
    SUBSCRIBE_COMBO_VIP = 'subscribe_combo_vip',
}

export enum SubscriptionOrderTypeActionEnum {
    UPGRADE_TABLE = 'upgrade-table',
    UPGRADE_LICENSE = 'upgrade-license',
    RENEW_LICENSE = 'renew-license',
    ADD_ON_PLAN = 'add-on-plan',
}
