import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import {
    APPLICATIONS,
    MESSAGE_SALES_PLAN_COLLABORATOR_SUCCESS,
    MESSAGE_SALES_PLAN_FREE_SUCCESS,
    MESSAGE_SALES_TRIAL_PLAN_SUCCESS,
} from '@constants/index.constant';
import { CurrencyType } from '@enums/currency-type.enum';
import { NApplication } from '@interfaces/application';
import { useApiUrl, useCustomMutation, useOne, useSetLocale } from '@refinedev/core';
import { monthsToYear, yearToMonths } from '@utils/function';
import { formatPriceBaseOnCurrency } from '@utils/resource';
import { Connector, LocationComponent, POS_PLAN_ID } from '@zodinet-pos/core-ui';
import type { CheckboxProps } from 'antd';
import { Button, Checkbox, Col, Form, Input, InputNumber, Layout, Row, Select, Spin } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { NotificationPaymentModal } from 'pages/extra-application-plan/notification-payment-modal';
import { QRModal } from 'pages/extra-application-plan/qr-modal';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getEnvConfig } from '../../getEnvConfig';
import { ILocation } from '../../interfaces/components/location';
import { validateIntNumber } from '../../utils/validator';
import { PlanItem } from './plan-item';
import s from './styles.module.scss';

export enum FieldTypeEnum {
    EMAIL = 'email',
    PASSWORD = 'password',
    FULL_NAME = 'fullName',
    PHONE_NUMBER = 'phoneNumber',
    QTY_TABLE = 'qtyTable',
    EXPIRED_TIME = 'expiredTime',
    REFERENCE_CODE = 'referenceCode',
    PLAN_TRIAL = 'isTrial',
    SERVICE_PACKAGE = 'servicePackage',
    AGREE_POLICY = 'isAgreePolicy',
}
const enum PriceTypeEnum {
    EXTRA_PLAN = 'extra_plan',
    TOTAL_PRICE = 'total_price',
}
const MAX_QTY_TABLE_FREE = 30;
const MIN_QTY_TABLE_PAID_SERVICE = 10;
const MIN_QTY_TABLE_ADD_ON_PLAN = 30;

export const EXPIRED_TIMES = [
    {
        value: 6,
    },
    {
        value: 12,
    },
];

export const ExtraApplicationPlanIndex = () => {
    const apiUrl = useApiUrl();
    const recaptchaRef = useRef<any>();

    const [form] = Form.useForm();
    const { t, i18n } = useTranslation(['common']);
    const { CAPTCHA_SITE_KEY } = getEnvConfig;

    const { mutate: createTenant } = useCustomMutation();
    const { id } = useParams();
    const changeLang = useSetLocale();

    const [qrUrl, setQrUrl] = useState<string>();
    const [qtyTable, setQtyTable] = useState<number>(1);
    const [registrationId, setRegistrationId] = useState<string>('');
    const [planPrice, setPlanPrice] = useState<number>(0);
    const [expiredTime, setExpiredTime] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [extraAppPlanId, setExtraAppPlanId] = useState<string>();
    const [extraAppPlanName, setExtraAppPlanName] = useState<string>();
    const [isOpenQrModal, setIsOpenQrModal] = useState<boolean>(false);
    const [subTitle, setSubTitle] = useState<string>();
    const [host, setHost] = useState<string>();
    const [refCode, setRefCode] = useState<string>();
    const [discount, setDiscount] = useState<number>(0);
    const [rate, setRate] = useState<number>(1);
    const [location, setLocation] = useState<ILocation.ILocationResponse | null>();
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const [isResetFields, setIsResetFields] = useState<boolean>(false);

    const [isTrial, setIsTrial] = useState<boolean>(false);

    const { data: extraAppPlans } = useOne<NApplication.IExtraAppPlanData>({
        resource: 'v1/application/extra-app-plans',
        id: APPLICATIONS.POS.id,
        queryOptions: {
            enabled: true,
        },
    });

    const { data: registration, refetch } = useOne<NApplication.IPlanResponseDto>({
        resource: 'v1/registration',
        id: id,
        queryOptions: {
            enabled: !!id,
        },
        errorNotification(error) {
            const response = error?.response?.data;
            return {
                message: t(`plan_form.errors.${response?.error}`),
                type: 'error',
            };
        },
    });

    useEffect(() => {
        changeLang('vi');
        if (id && registration?.data) {
            form.setFieldsValue({
                ...registration.data,
                [FieldTypeEnum.AGREE_POLICY]: true,
                [FieldTypeEnum.PLAN_TRIAL]: registration.data.isTrial,
            });
            setExtraAppPlanId(registration.data.extraAppPlanId);
            setPlanPrice(registration.data.planPrice);
            setQtyTable(registration.data.qtyTable);
            setExtraAppPlanName(registration.data.extraAppPlanName);
            setExpiredTime(registration.data.expiredTime);
            setRefCode(registration.data.referenceCode);
            setDiscount(registration.data.discount || 0);
            setRate(registration.data.rate || 0);
            setLocation(registration.data.location || null);
            setIsTrial(!!registration.data.isTrial);
        }
    }, [registration, qrUrl, registrationId]);

    const onSubmit = async (plan: NApplication.IExtraAppPlan) => {
        if (!id) {
            setPlanPrice(plan.pricing);
            setExtraAppPlanId(plan.id);
            setExtraAppPlanName(plan.title);
        }

        await form.validateFields();

        if (!form.getFieldValue(FieldTypeEnum.AGREE_POLICY)) {
            return;
        }

        setIsSubmit(true);
    };

    const handleSubmit = async (values: NApplication.IPlanRequest) => {
        setIsLoading(true);
        const requestForm: NApplication.IPlanRequest = {
            ...values,
            planPrice: values.isTrial ? 0 : planPrice, // if user is using trial standard plan, set price to 0
            extraAppPlanId: extraAppPlanId ?? '',
            extraAppPlanName: extraAppPlanName ?? '',
            id: id || registrationId || undefined,
            isUser: !id,
            fnbPrice:
                extraAppPlans?.data?.plans?.find((p) => p.id === extraAppPlanId)?.fnbPricing ?? 0,
        };

        const recaptchaToken = await recaptchaRef.current.executeAsync();
        createTenant(
            {
                url: `${apiUrl}/v1/registration`,
                method: 'post',
                values: requestForm,
                config: {
                    headers: {
                        recaptchavalue: recaptchaToken ?? null,
                    },
                },
                errorNotification: (error) => {
                    setIsLoading(false);
                    recaptchaRef.current.reset();
                    const code = error?.response?.data?.error;
                    if (i18n.exists(`plan_form.errors.${code}`)) {
                        return {
                            message: t(`plan_form.errors.${code}`, { ns: 'common' }),
                            type: 'error',
                        };
                    } else {
                        return { message: code, type: 'error' };
                    }
                },
            },
            {
                onSuccess: (data) => {
                    const response: NApplication.IPlanResponse = data.data?.data;
                    if (data?.data?.isSuccess) {
                        switch (response.qrUrl) {
                            case MESSAGE_SALES_PLAN_FREE_SUCCESS: {
                                setSubTitle('notifications.waiting_active');
                                setIsOpenQrModal(false);
                                break;
                            }
                            case MESSAGE_SALES_TRIAL_PLAN_SUCCESS: {
                                setIsOpenQrModal(false);
                                if (response?.host) {
                                    setHost(response.host);
                                } else {
                                    setSubTitle('notifications.waiting_active');
                                }

                                break;
                            }
                            case MESSAGE_SALES_PLAN_COLLABORATOR_SUCCESS: {
                                setSubTitle('notifications.waiting_collaborator');
                                setIsOpenQrModal(false);
                                resetStateAfterSubmitSuccess();
                                break;
                            }
                            default: {
                                setIsOpenQrModal(true);
                                setQrUrl(response.qrUrl);
                                response.registrationId &&
                                    setRegistrationId(response.registrationId);
                            }
                        }
                    }
                    recaptchaRef.current.reset();
                    setIsLoading(false);
                },
            },
        );
    };
    const onValidation = async (result?: boolean, data?: ILocation.ILocationResponse) => {
        setIsSubmit(false);

        if (!result || !data) {
            return null;
        }

        const values = form.getFieldsValue() as NApplication.IPlanRequest;
        values.location = { ...data };
        setLocation(data);

        handleSubmit(values);
    };

    const onChangeUsingTrial: CheckboxProps['onChange'] = (e) => {
        const checked = e.target.checked;
        if (checked) {
            form.setFieldValue(FieldTypeEnum.EXPIRED_TIME, 0);
            setExpiredTime(0);
            form.validateFields([FieldTypeEnum.EXPIRED_TIME]);
        }

        setIsTrial(checked);
        form.setFieldValue(FieldTypeEnum.PLAN_TRIAL, checked);
    };

    const resetStateAfterSubmitSuccess = () => {
        setPlanPrice(0);
        setExtraAppPlanId('');
        setExtraAppPlanName('');
        setIsTrial(false);
        setIsResetFields(false);
    };

    useEffect(() => {
        if (isResetFields) {
            setIsResetFields(false);
        }
    }, [isResetFields]);

    const totalPrice = useMemo((): number => {
        const months = yearToMonths(expiredTime);
        const planTotal = (planPrice * months * qtyTable * (100 - discount)) / 100;

        const plan = extraAppPlans?.data.plans.find((plan) => plan.id === extraAppPlanId);
        const fnbTotal = Math.round(((plan?.fnbPricing ?? 0) * months) / rate);

        return planTotal + fnbTotal;
    }, [planPrice, expiredTime, qtyTable, discount, extraAppPlanId, rate]);

    const availablePlans = !id
        ? extraAppPlans?.data?.plans?.filter((p) => p.id !== POS_PLAN_ID.STANDARD_FNB) || []
        : [];

    const renderPrice = ({ type }: { type: PriceTypeEnum }) => {
        switch (type) {
            case PriceTypeEnum.TOTAL_PRICE: {
                if (planPrice < 0 || expiredTime < 0 || qtyTable < 0) return '--';

                if (registration?.data.isTrial && form.getFieldValue(FieldTypeEnum.PLAN_TRIAL))
                    return formatPriceBaseOnCurrency(0, CurrencyType.Vnd);

                return formatPriceBaseOnCurrency(totalPrice, CurrencyType.Vnd);
            }
        }
    };

    return (
        <>
            <Connector.default brokerUrl={getEnvConfig.MQTT_URL} options={{ keepalive: 60 }}>
                <Layout className={`flex items-center justify-center ${s.planPage}`}>
                    <Spin spinning={isLoading}>
                        <Form
                            form={form}
                            layout="vertical"
                            className={s.planContainer}
                            onFinish={onSubmit}
                            disabled={!!id}
                        >
                            <section className={s.headerContainer}>
                                <div className={s.logoContainer}>
                                    <img
                                        src="/images/ffood_logo.png"
                                        alt="logo"
                                        className={s.logoImg}
                                    />
                                </div>
                                <p className={`mr-2 ${s.titleForm}`}>{t('plan_form.title')}</p>
                            </section>

                            <section className={s.formContainer}>
                                <div className={`flex flex-col ${s.formWrapper}`}>
                                    <Row className="w-full" gutter={32}>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={t('plan_form.fields.email')}
                                                name={FieldTypeEnum.EMAIL}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t(
                                                            'plan_form.fields.email_required',
                                                        ),
                                                    },
                                                    {
                                                        type: 'email',
                                                        message: t(
                                                            'plan_form.fields.email_invalid',
                                                        ),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder={t(
                                                        'plan_form.fields.email_placeholder',
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                        {!id && (
                                            <Col xs={24} md={12}>
                                                <Form.Item
                                                    label={t('plan_form.fields.password')}
                                                    name={FieldTypeEnum.PASSWORD}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t(
                                                                'plan_form.fields.password_required',
                                                            ),
                                                        },
                                                        {
                                                            pattern: /^[a-zA-Z0-9]*$/,
                                                            message: t(
                                                                'plan_form.fields.valid_password',
                                                            ),
                                                        },
                                                    ]}
                                                >
                                                    <Input.Password
                                                        placeholder={t(
                                                            'plan_form.fields.password_placeholder',
                                                        )}
                                                        iconRender={(visible) =>
                                                            visible ? (
                                                                <EyeTwoTone />
                                                            ) : (
                                                                <EyeInvisibleOutlined />
                                                            )
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                        )}
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={t('plan_form.fields.full_name')}
                                                name={FieldTypeEnum.FULL_NAME}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t(
                                                            'plan_form.fields.full_name_required',
                                                        ),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder={t(
                                                        'plan_form.fields.full_name_placeholder',
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={t('plan_form.fields.phone_number')}
                                                name={FieldTypeEnum.PHONE_NUMBER}
                                                rules={[
                                                    {
                                                        pattern: /\(\d{3}\)-\d{3}-\d{4}/,
                                                        message: t('settings.phone_number_invalid'),
                                                    },
                                                    {
                                                        required: true,
                                                        message: t(
                                                            'settings.phone_number_required',
                                                        ),
                                                    },
                                                ]}
                                            >
                                                <MaskedInput
                                                    disabled={!!id}
                                                    mask={
                                                        //  https://imask.js.org/guide.html#masked-pattern
                                                        '(000)-000-0000'
                                                    }
                                                    placeholder={form.getFieldValue(
                                                        FieldTypeEnum.PHONE_NUMBER,
                                                    )}
                                                    value={form.getFieldValue(
                                                        FieldTypeEnum.PHONE_NUMBER,
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                        {id && !location ? (
                                            <></>
                                        ) : (
                                            <Col span={24}>
                                                <LocationComponent
                                                    location={location}
                                                    isSubmit={isSubmit}
                                                    setIsSubmit={setIsSubmit}
                                                    isPosCode={false}
                                                    onValidation={onValidation}
                                                    disable={!!id}
                                                    isResetFields={isResetFields}
                                                    spanItems={[0, 8, 8, 8]}
                                                    spanAddress={[0, 24]}
                                                />
                                            </Col>
                                        )}
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={t('plan_form.fields.qty_table')}
                                                name={FieldTypeEnum.QTY_TABLE}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t(
                                                            'plan_form.fields.qty_table_required',
                                                        ),
                                                    },
                                                    {
                                                        validator: async (_, value) => {
                                                            if (value && !validateIntNumber(value))
                                                                return Promise.reject(
                                                                    t(
                                                                        'plan_form.fields.number_invalid',
                                                                        {
                                                                            ns: 'common',
                                                                        },
                                                                    ),
                                                                );
                                                            if (value === 0) {
                                                                return Promise.reject(
                                                                    t(
                                                                        'plan_form.fields.qty_table_greater_than_zero',
                                                                    ),
                                                                );
                                                            }

                                                            const servicePackageFree =
                                                                extraAppPlans?.data?.plans?.find(
                                                                    (extraAppPlan) =>
                                                                        extraAppPlan.title ===
                                                                        'FREE',
                                                                );

                                                            const isSelectServicePackageFree =
                                                                extraAppPlanId ===
                                                                servicePackageFree?.id;

                                                            const isSelectedAddOnPlan =
                                                                POS_PLAN_ID.STANDARD_FNB ===
                                                                extraAppPlanId;

                                                            //if selected add on plan, min qty is 30
                                                            if (
                                                                value &&
                                                                extraAppPlanId &&
                                                                isSelectedAddOnPlan &&
                                                                value < MIN_QTY_TABLE_ADD_ON_PLAN
                                                            ) {
                                                                return Promise.reject(
                                                                    t(
                                                                        'plan_form.fields.minimum_add_on_qty',
                                                                        {
                                                                            pcs: MIN_QTY_TABLE_ADD_ON_PLAN,
                                                                        },
                                                                    ),
                                                                );
                                                            }

                                                            //if selected plan not free, min qty is 10
                                                            if (
                                                                value &&
                                                                extraAppPlanId &&
                                                                !isSelectServicePackageFree &&
                                                                value < MIN_QTY_TABLE_PAID_SERVICE
                                                            ) {
                                                                return Promise.reject(
                                                                    t(
                                                                        'plan_form.fields.minimum_qty',
                                                                        {
                                                                            pcs: MIN_QTY_TABLE_PAID_SERVICE,
                                                                        },
                                                                    ),
                                                                );
                                                            }

                                                            //if selected free plan, max qty is 30
                                                            if (
                                                                value &&
                                                                extraAppPlanId &&
                                                                isSelectServicePackageFree &&
                                                                value > MAX_QTY_TABLE_FREE
                                                            ) {
                                                                return Promise.reject(
                                                                    t(
                                                                        'plan_form.fields.qty_table_less_than_or_equal',
                                                                        {
                                                                            pcs: MAX_QTY_TABLE_FREE,
                                                                        },
                                                                    ),
                                                                );
                                                            }

                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    placeholder={t(
                                                        'plan_form.fields.qty_table_placeholder',
                                                    )}
                                                    onChange={(value: number | null) =>
                                                        setQtyTable(value ?? 1)
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={t('plan_form.fields.expired_time')}
                                                name={FieldTypeEnum.EXPIRED_TIME}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t(
                                                            'plan_form.fields.expired_time_required',
                                                        ),
                                                    },
                                                    {
                                                        validator: async (_, value) => {
                                                            if (
                                                                (!value && value !== 0) ||
                                                                value > 0
                                                            ) {
                                                                return Promise.resolve();
                                                            }

                                                            if (
                                                                form.getFieldValue(
                                                                    FieldTypeEnum.PLAN_TRIAL,
                                                                )
                                                            ) {
                                                                return Promise.resolve();
                                                            }

                                                            return Promise.reject(
                                                                t(
                                                                    'plan_form.fields.expired_time_greater_than_zero',
                                                                ),
                                                            );
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    disabled={!!id}
                                                    getPopupContainer={(trigger) =>
                                                        trigger.parentNode
                                                    }
                                                    showSearch={false}
                                                    placeholder={t(
                                                        'plan_form.fields.expired_time_placeholder',
                                                    )}
                                                    onChange={(value) => {
                                                        setExpiredTime(value ?? 0);
                                                    }}
                                                >
                                                    {EXPIRED_TIMES.map((time) => {
                                                        return (
                                                            <Select.Option
                                                                key={time.value}
                                                                value={monthsToYear(time.value)}
                                                            >
                                                                {time.value}{' '}
                                                                {t(
                                                                    'subscription_order.frequency_type.month',
                                                                )}
                                                            </Select.Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={t('plan_form.fields.reference_code')}
                                                name={FieldTypeEnum.REFERENCE_CODE}
                                            >
                                                <Input
                                                    placeholder={t(
                                                        'plan_form.fields.reference_code_placeholder',
                                                    )}
                                                    value={refCode}
                                                    onChange={(e) => setRefCode(e.target.value)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={24}>
                                            <Form.Item
                                                name={FieldTypeEnum.AGREE_POLICY}
                                                valuePropName="checked"
                                                initialValue={true}
                                                rules={[
                                                    {
                                                        validator: (_, value) => {
                                                            if (!value) {
                                                                return Promise.reject(
                                                                    t('policy_agreement_required'),
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Checkbox>
                                                    {
                                                        <Button
                                                            type="link"
                                                            className="p-0 text-black"
                                                        >
                                                            {t('policy_agreement')}
                                                        </Button>
                                                    }
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Form.Item
                                        name={FieldTypeEnum.SERVICE_PACKAGE}
                                        rules={[
                                            {
                                                required: !extraAppPlanId,
                                                message: t(
                                                    'plan_form.fields.service_package_required',
                                                ),
                                            },
                                        ]}
                                        className="mb-0"
                                    >
                                        {!id ? (
                                            <section className="flex flex-col gap-y-6">
                                                <section>
                                                    <p className="w-full flex justify-center leading-8 text-2xl font-semibold">
                                                        {t('select_plan_service')?.toUpperCase()}
                                                    </p>
                                                </section>
                                                <section className="w-full">
                                                    <Row gutter={[24, 12]}>
                                                        {availablePlans.map((plan) => (
                                                            <Col span={8} key={plan.id}>
                                                                <PlanItem
                                                                    isPopular={[
                                                                        POS_PLAN_ID.STANDARD,
                                                                    ].includes(plan.id)}
                                                                    plan={plan}
                                                                    discount={discount}
                                                                    addOnPlan={
                                                                        [
                                                                            POS_PLAN_ID.STANDARD,
                                                                        ].includes(plan.id)
                                                                            ? extraAppPlans?.data.plans.find(
                                                                                  (p) =>
                                                                                      p.id ===
                                                                                      POS_PLAN_ID.STANDARD_FNB,
                                                                              )
                                                                            : undefined
                                                                    }
                                                                    isUsingForm={true}
                                                                    trialLimitDays={
                                                                        extraAppPlans?.data
                                                                            .trialLimitDays
                                                                    }
                                                                    onChangeUsingTrial={
                                                                        onChangeUsingTrial
                                                                    }
                                                                    isTrial={
                                                                        [
                                                                            POS_PLAN_ID.STANDARD,
                                                                        ].includes(plan.id) &&
                                                                        isTrial
                                                                    }
                                                                    onRegister={onSubmit}
                                                                />
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </section>
                                            </section>
                                        ) : (
                                            <>
                                                {registration?.data?.extraAppPlanName ? (
                                                    <section>
                                                        <p className="leading-8 text-2xl font-semibold flex">
                                                            <span className="mr-2">{`${t(
                                                                'registered_plan',
                                                            )}:`}</span>
                                                            <span className="text-[#FF8057]">
                                                                {t(
                                                                    `plan_form.plan_type.${registration?.data.extraAppPlanName.toLowerCase()}`,
                                                                )}
                                                                {registration?.data.isTrial
                                                                    ? ` (${t('plan_form.trial')})`
                                                                    : ''}
                                                            </span>
                                                        </p>
                                                        <input className="hidden" />
                                                    </section>
                                                ) : null}
                                            </>
                                        )}
                                    </Form.Item>
                                </div>
                            </section>
                            {(registration?.data.tenantId && registration?.data.userId) ||
                            (id && !registration) ||
                            !id ? (
                                <></>
                            ) : (
                                <div className={s.btnContainer}>
                                    {!registration?.data.isTrial ? (
                                        <Button
                                            type="primary"
                                            className={s.btn}
                                            htmlType="submit"
                                            disabled={false}
                                        >
                                            {id
                                                ? `${t(
                                                      'plan_form.fields.payment_btn',
                                                  )} - ${renderPrice({
                                                      type: PriceTypeEnum.TOTAL_PRICE,
                                                  })}`
                                                : t('buttons.subscribe', { ns: 'common' })}
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            )}

                            <ReCAPTCHA
                                size="invisible"
                                badge="bottomleft"
                                ref={recaptchaRef}
                                sitekey={CAPTCHA_SITE_KEY}
                            />
                        </Form>
                    </Spin>
                </Layout>

                {qrUrl && (
                    <QRModal
                        url={qrUrl}
                        totalPrice={totalPrice}
                        isOpen={isOpenQrModal}
                        onClose={() => {
                            setQrUrl(undefined);
                            setIsOpenQrModal(false);
                        }}
                    />
                )}
                {(registrationId || subTitle || host) && (
                    <NotificationPaymentModal
                        objectId={registrationId}
                        onClose={() => {
                            setRegistrationId('');
                            setSubTitle('');
                            setHost('');
                            form.resetFields();
                            resetStateAfterSubmitSuccess();

                            if (id) refetch();
                            else {
                                setIsResetFields(true);
                            }
                        }}
                        onCloseQrModal={() => {
                            setQrUrl(undefined);
                            setIsOpenQrModal(false);
                            resetStateAfterSubmitSuccess();
                        }}
                        subTitle={subTitle}
                        id={id}
                        host={host}
                    />
                )}
            </Connector.default>
        </>
    );
};
