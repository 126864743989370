import { DashboardReport } from '@components/subscription-plan/dashboard-report';
import { DefaultDashboard } from '@components/subscription-plan/default-dashboard';
import {
    useAvailableModules,
    useHandleModulesSubscriptionPayment,
    useHandlePaymentSubscriptionOrder,
    useHandleRegistrationActionPayment,
} from '@components/subscription-plan/hooks';
import { ModuleItemModal } from '@components/subscription-plan/module-item-modal';
import { ModulesBanner } from '@components/subscription-plan/modules-banner';
import { PlanInformation } from '@components/subscription-plan/plan-information';
import { SubscribeModuleModal } from '@components/subscription-plan/subscribe-module-modal';
import { IMarketplace } from '@interfaces/marketplace';
import {
    HttpError,
    useApiUrl,
    useCustom,
    useCustomMutation,
    useGetIdentity,
} from '@refinedev/core';
import { Col, Divider, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    APPLICATIONS,
    MESSAGE_CONTACT_COLLABORATOR_TO_ADD_ON,
    MESSAGE_CONTACT_COLLABORATOR_TO_RENEW,
    MESSAGE_CONTACT_COLLABORATOR_TO_UPGRADE,
    MESSAGE_CONTACT_COLLABORATOR_TO_UPGRADE_APPLICATION,
    TIMEZONE_DEFAULT,
} from '../../constants/index.constant';
import Response from '../../data-access/responses/response';
import {
    SubscriptionOrderTypeActionEnum,
    SubscriptionOrderTypeEnum,
} from '../../enums/subscription-order-type.enum';
import { NApplication } from '../../interfaces/application';
import { NRegistration } from '../../interfaces/registration';
import { NSubscriptionOrder } from '../../interfaces/subscription-order';
import { IUser } from '../../interfaces/user';
import { IntroduceIndex } from '../../pages/dashboard/introduce';
import { NotificationPaymentModal } from '../../pages/extra-application-plan/notification-payment-modal';
import { NotificationInformationModal } from '../../pages/extra-application-plan/notification-payment-modal/notification-information';
import { QRModal } from '../../pages/extra-application-plan/qr-modal';
import { SubscriptionOrder } from '../subscription-order';
import { AddOnPlanModal } from './add-on-plan-modal';
import { UpgradeLicenseModal } from './upgrade-license-modal';
import { UpgradeModal } from './upgrade-modal';

export type IForm = { quantity?: number; referenceCode: string; extraAppPlanId?: string };

export type SubscriptionForm = {
    applicationId: string;
    id?: string;
    quantity?: number;
    referenceCode?: string;
    applicationPlanId?: string;
};

export type OpenQrPaymentType = {
    totalPayment: number;
    url: string;
    subscriptionOrderId: string;
    title: string;
};

export const SubscriptionPlan: React.FC = () => {
    const apiUrl = useApiUrl();
    const { t, i18n } = useTranslation('common');
    const { mutate } = useCustomMutation<Response<NApplication.IPlanResponse>>();

    const { data: userIdentity } = useGetIdentity<IUser.IUserDto>();

    const [registration, setRegistration] = useState<NRegistration.IRegistrationForDashboard>();
    const [userPlan, setUserPlan] = useState<NRegistration.TUsedPlan>();
    const [isOpenUpgradeModal, setIsOpenUpgradeModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [qrUrl, setQrUrl] = useState<string>();
    const [isOpenQrModal, setIsOpenQrModal] = useState<boolean>(false);
    const [typeModal, setTypeModal] = useState<SubscriptionOrderTypeEnum>();
    const [subTitle, setSubTitle] = useState<string>();
    const [title, setTitle] = useState<string>();
    const [isRefetch, setIsRefetch] = useState<boolean>(false);
    const [subscriptionOrderId, setSubscriptionOrderId] = useState<string>();
    const [totalPayment, setTotalPayment] = useState<number>(0);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedApplication, setSelectedApplication] = useState<IMarketplace.IApplication>();
    const [notificationFirstBuying, setNotification] = useState<string>();

    const isTenantOwner = userIdentity?.isTenantOwner;

    // HOOK
    const applications = useAvailableModules();

    const {
        data,
        refetch: refetchDetail,
        isLoading: detailLoading,
    } = useCustom<
        Response<NRegistration.IRegistrationForDashboard | NRegistration.TUsedPlan>,
        HttpError
    >({
        url: `${apiUrl}/v1/registration/detail`,
        method: 'get',
        config: {},
        queryOptions: {
            enabled: isTenantOwner,
        },
    });

    useEffect(() => {
        if (data?.data?.data) {
            if ((data.data.data as NRegistration.IRegistrationForDashboard).id) {
                const _registration = data.data.data as NRegistration.IRegistrationForDashboard;
                setUserPlan(_registration.usedPlan);
                setRegistration(_registration);
            } else setUserPlan(data.data.data as NRegistration.TUsedPlan);
        }
    }, [data]);

    const handleRegistrationActionPayment = (values: IForm) => {
        const _values: IForm & { timezone: string } = { ...values, timezone: TIMEZONE_DEFAULT };

        if (!registration || !typeModal) return;

        setIsLoading(true);

        const key: keyof typeof SubscriptionOrderTypeActionEnum =
            typeModal.toUpperCase() as keyof typeof SubscriptionOrderTypeActionEnum;
        const action = SubscriptionOrderTypeActionEnum[key];

        const onSuccess = (response: NApplication.IPlanResponse) => {
            onChangeIsOpenUpgradeModal(false);
            setIsLoading(false);

            switch (response.qrUrl) {
                case MESSAGE_CONTACT_COLLABORATOR_TO_UPGRADE:
                case MESSAGE_CONTACT_COLLABORATOR_TO_RENEW:
                case MESSAGE_CONTACT_COLLABORATOR_TO_ADD_ON: {
                    setTitle(t(`plan_form.actions.${typeModal}`));
                    setSubTitle(`notifications.${response.qrUrl}`);
                    setIsOpenQrModal(false);
                    break;
                }
                default: {
                    handleOpenQrPayment({
                        totalPayment: response.paymentAmount || 0,
                        url: response.qrUrl,
                        subscriptionOrderId: response.subscriptionOrderId as string,
                        title: t(`plan_form.success.${typeModal}_success`),
                    });
                }
            }
        };

        // HOOK
        useHandleRegistrationActionPayment({
            apiUrl,
            mutate,
            values: _values,
            action,
            registrationId: registration.id,
            onSuccess,
            onError: onLogError,
        });
    };

    const handleModulesSubscriptionPayment = (values: SubscriptionForm) => {
        const _values: SubscriptionForm & { timezone: string } = {
            ...values,
            timezone: TIMEZONE_DEFAULT,
        };
        setIsLoading(true);
        // HOOK
        useHandleModulesSubscriptionPayment({
            apiUrl,
            mutate,
            values: _values,
            typeModal,
            onSuccess: handleOpenQrPayment,
            onError: onLogError,
        });
    };

    const handlePaymentSubscriptionOrder = (subscriptionOrder: NSubscriptionOrder.IInfo) => {
        setIsLoading(true);
        // HOOK
        useHandlePaymentSubscriptionOrder({
            apiUrl,
            subscriptionOrder,
            mutate,
            onSuccess: onPaymentSubscriptionOrderSuccess,
            onError: onLogError,
        });
    };

    const handleNotiResetState = () => {
        onChangeIsOpenUpgradeModal(false);
        setTypeModal(undefined);
        refetchDetail();
        onChangeQrUrl(undefined);
        setSubTitle(undefined);
        setTitle(undefined);
        handleRefetchSubscriptionOrder();
        setSubscriptionOrderId(undefined);
    };

    const handleCloseModal = () => {
        onChangeIsOpenUpgradeModal(false);
        setTypeModal(undefined);
        handleRefetchSubscriptionOrder();
    };

    const handleRefetchSubscriptionOrder = () => {
        setIsRefetch(true);
    };

    const onChangeQrUrl = (url?: string) => {
        setQrUrl(url);
    };

    const handleOpenQrPayment = (paymentData: OpenQrPaymentType) => {
        const { totalPayment, url, subscriptionOrderId } = paymentData;
        // Update state with essential payment details
        setSubscriptionOrderId(subscriptionOrderId);
        setTitle(title);
        setIsLoading(false);
        setTotalPayment(totalPayment);

        const isUpgradeApplicationMessage =
            url === MESSAGE_CONTACT_COLLABORATOR_TO_UPGRADE_APPLICATION;

        if (isUpgradeApplicationMessage) {
            setIsOpenUpgradeModal(false);
            handleRefetchSubscriptionOrder();
            setTitle(t(`plan_form.actions.${typeModal}`));
            setSubTitle(`notifications.${url}`);
            return;
        }

        // If the URL is not for upgrading the application, proceed with QR modal
        setIsOpenQrModal(true);
        onChangeQrUrl(url);

        const shouldShowSubTitle =
            typeModal &&
            [
                SubscriptionOrderTypeEnum.UPGRADE_PAYGATE_TRANSACTION,
                SubscriptionOrderTypeEnum.UPGRADE_TAX_TRANSACTION,
                SubscriptionOrderTypeEnum.UPGRADE_TICKET_TRANSACTION,
                SubscriptionOrderTypeEnum.SUBSCRIBE_CLOUD,
                SubscriptionOrderTypeEnum.SUBSCRIBE_FNET_MOBILE,
                SubscriptionOrderTypeEnum.SUBSCRIBE_COMBO_VIP,
            ].includes(typeModal);

        if (shouldShowSubTitle) {
            setNotification(t('notifications.refresh_token'));
        }
    };

    const onChangeOpenModal = (value: boolean, module?: IMarketplace.IApplication) => {
        setOpenModal(value);
        setSelectedApplication(module);
        switch (module?.id) {
            case APPLICATIONS.PAYGATE.id:
                setTypeModal(SubscriptionOrderTypeEnum.UPGRADE_PAYGATE_TRANSACTION);
                break;
            case APPLICATIONS.TAX.id:
                setTypeModal(SubscriptionOrderTypeEnum.UPGRADE_TAX_TRANSACTION);
                break;
            case APPLICATIONS.TICKET.id:
                setTypeModal(SubscriptionOrderTypeEnum.UPGRADE_TICKET_TRANSACTION);
                break;
            case APPLICATIONS.CLOUD.id:
                onOpenUpgradeModal(SubscriptionOrderTypeEnum.SUBSCRIBE_CLOUD);
                break;
            case APPLICATIONS.FNET_MOBILE.id:
                onOpenUpgradeModal(SubscriptionOrderTypeEnum.SUBSCRIBE_FNET_MOBILE);
                break;
            case APPLICATIONS.COMBO_VIP.id:
                onOpenUpgradeModal(SubscriptionOrderTypeEnum.SUBSCRIBE_COMBO_VIP);
                break;
        }
    };

    const onChangeIsOpenUpgradeModal = (value: boolean) => {
        setIsOpenUpgradeModal(value);
    };

    const onOpenUpgradeModal = (type: SubscriptionOrderTypeEnum) => {
        setTypeModal(type);
        onChangeIsOpenUpgradeModal(true);
    };

    const onPaymentSubscriptionOrderSuccess = (
        response: NApplication.IPlanResponse,
        type: SubscriptionOrderTypeEnum,
    ) => {
        setTitle(t(`plan_form.success.${type}_success`));
        setTypeModal(type);
        setIsOpenQrModal(true);
        onChangeQrUrl(response.qrUrl);
        setTotalPayment(response.paymentAmount || 0);
        setIsLoading(false);
    };

    const onLogError = (dto: { code: string }) => {
        const { code } = dto;
        setIsLoading(false);

        if (i18n.exists(`plan_form.errors.${code}`)) {
            return t(`plan_form.errors.${code}`);
        } else {
            return code;
        }
    };

    const onClickUpgradeTable = () => {
        setTypeModal(SubscriptionOrderTypeEnum.UPGRADE_TABLE);
        onChangeIsOpenUpgradeModal(true);
    };

    return (
        <>
            {!isTenantOwner ? (
                <>
                    <IntroduceIndex />
                </>
            ) : !detailLoading && !registration ? (
                <>
                    <DefaultDashboard
                        userPlan={userPlan}
                        applications={applications}
                        registration={registration}
                        onChangeOpenModal={onChangeOpenModal}
                        onClickUpgradeTable={onClickUpgradeTable}
                    />

                    <SubscriptionOrder
                        isRefetch={isRefetch}
                        setIsRefetch={setIsRefetch}
                        handlePaymentSubscriptionOrder={(record) => {
                            setSubscriptionOrderId(record.id);
                            handlePaymentSubscriptionOrder(record);
                        }}
                    />
                </>
            ) : (
                <Spin spinning={isLoading}>
                    {registration ? (
                        <>
                            <section className="plan-detail">
                                <Row gutter={[0, 48]} className="w-full justify-between">
                                    <Col span={24} className="rounded-lg border p-5">
                                        <Row gutter={[20, 20]}>
                                            <Col span={24} xl={11}>
                                                <PlanInformation
                                                    registration={registration}
                                                    onOpenUpgradeModal={onOpenUpgradeModal}
                                                />
                                            </Col>
                                            <Col className="hidden xl:block">
                                                <Divider
                                                    type="vertical"
                                                    className=" h-auto min-h-full bg-[#F3F4F6]"
                                                />
                                            </Col>
                                            <Col span={24} xl={12}>
                                                <DashboardReport
                                                    userPlan={userPlan}
                                                    applications={applications}
                                                    registration={registration}
                                                    onChangeOpenModal={onChangeOpenModal}
                                                    onClickUpgradeTable={onClickUpgradeTable}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </section>

                            <ModulesBanner
                                onChangeOpenModal={onChangeOpenModal}
                                applications={applications}
                            />

                            <SubscriptionOrder
                                isRefetch={isRefetch}
                                setIsRefetch={setIsRefetch}
                                handlePaymentSubscriptionOrder={(record) => {
                                    setSubscriptionOrderId(record.id);
                                    handlePaymentSubscriptionOrder(record);
                                }}
                            />

                            {isOpenUpgradeModal && typeModal ? (
                                <>
                                    {[SubscriptionOrderTypeEnum.UPGRADE_LICENSE].includes(
                                        typeModal,
                                    ) && (
                                        <UpgradeLicenseModal
                                            isOpen={isOpenUpgradeModal}
                                            onClose={handleCloseModal}
                                            handlePayment={handleRegistrationActionPayment}
                                            isLoading={isLoading}
                                            registration={registration}
                                        />
                                    )}

                                    {[
                                        SubscriptionOrderTypeEnum.UPGRADE_TABLE,
                                        SubscriptionOrderTypeEnum.RENEW_LICENSE,
                                    ].includes(typeModal) && (
                                        <UpgradeModal
                                            type={typeModal}
                                            isOpen={isOpenUpgradeModal}
                                            onClose={handleCloseModal}
                                            handlePayment={handleRegistrationActionPayment}
                                            isLoading={isLoading}
                                            registration={registration}
                                        />
                                    )}

                                    {[SubscriptionOrderTypeEnum.ADD_ON_PLAN].includes(
                                        typeModal,
                                    ) && (
                                        <AddOnPlanModal
                                            type={typeModal}
                                            isOpen={isOpenUpgradeModal}
                                            onClose={handleCloseModal}
                                            handlePayment={handleRegistrationActionPayment}
                                            isLoading={isLoading}
                                            registration={registration}
                                        />
                                    )}
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </Spin>
            )}
            {selectedApplication &&
                typeModal &&
                [
                    SubscriptionOrderTypeEnum.SUBSCRIBE_CLOUD,
                    SubscriptionOrderTypeEnum.SUBSCRIBE_FNET_MOBILE,
                    SubscriptionOrderTypeEnum.SUBSCRIBE_COMBO_VIP,
                ].includes(typeModal) && (
                    <SubscribeModuleModal
                        isOpen={isOpenUpgradeModal}
                        onClose={handleCloseModal}
                        handlePayment={handleModulesSubscriptionPayment}
                        isLoading={isLoading}
                        registration={registration}
                        application={selectedApplication}
                    />
                )}

            {subTitle && !subscriptionOrderId && (
                <NotificationInformationModal
                    onClose={handleNotiResetState}
                    title={title || ''}
                    subTitle={subTitle || ''}
                />
            )}
            {qrUrl && (
                <>
                    <QRModal
                        url={qrUrl}
                        isOpen={isOpenQrModal}
                        totalPrice={totalPayment}
                        onClose={() => {
                            onChangeQrUrl(undefined);
                            setIsOpenQrModal(false);
                            handleRefetchSubscriptionOrder();
                            setSubscriptionOrderId(undefined);
                            setSubTitle(undefined);
                        }}
                    />
                </>
            )}
            {subscriptionOrderId && (
                <NotificationPaymentModal
                    objectId={subscriptionOrderId || ''}
                    onClose={handleNotiResetState}
                    onCloseQrModal={() => {
                        onChangeQrUrl(undefined);
                        setIsOpenQrModal(false);
                    }}
                    subTitle={subTitle}
                    title={title}
                    id={registration?.id}
                    notification={notificationFirstBuying}
                    showDetailBtn={
                        ![
                            SubscriptionOrderTypeEnum.UPGRADE_PAYGATE_TRANSACTION,
                            SubscriptionOrderTypeEnum.UPGRADE_TAX_TRANSACTION,
                            SubscriptionOrderTypeEnum.UPGRADE_TICKET_TRANSACTION,
                            SubscriptionOrderTypeEnum.SUBSCRIBE_CLOUD,
                            SubscriptionOrderTypeEnum.SUBSCRIBE_FNET_MOBILE,
                            SubscriptionOrderTypeEnum.SUBSCRIBE_COMBO_VIP,
                        ].includes(typeModal as SubscriptionOrderTypeEnum)
                    }
                />
            )}

            {openModal &&
                selectedApplication &&
                [
                    SubscriptionOrderTypeEnum.UPGRADE_PAYGATE_TRANSACTION,
                    SubscriptionOrderTypeEnum.UPGRADE_TAX_TRANSACTION,
                    SubscriptionOrderTypeEnum.UPGRADE_TICKET_TRANSACTION,
                ].includes(typeModal as SubscriptionOrderTypeEnum) && (
                    <ModuleItemModal
                        isLoading={false}
                        handleOpenQrPayment={handleOpenQrPayment}
                        isOpen={openModal}
                        item={selectedApplication}
                        registrationId={registration?.id}
                        onClose={() => onChangeOpenModal(false)}
                        typeModal={typeModal}
                        setSubscriptionOrderId={setSubscriptionOrderId}
                        setTitle={setTitle}
                        setSubTitle={setSubTitle}
                    />
                )}
        </>
    );
};
